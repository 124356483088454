
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { AppStore } from '@interface/store/App';
import MyNightButton from '@part/elements/MyNightButton.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        MyNightButton
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {}
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class CardLong extends Vue {}
