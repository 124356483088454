
/* ----------------------------------------
    Imports
---------------------------------------- */

import $ from 'jquery';
import uniqid from 'uniqid';
import PlaySvg from '@svg/Play.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        PlaySvg
    },
    props: ['data'],
    data() {
        return {
            el: null,
            url: null,
            videoObj: null,
            imgEl: null,
            iframeEl: null,
            iframe: null,
            thumbEl: null,
            uniqueId: uniqid()
        };
    },
    methods: {
        parseVideo() {
            this.url.match(
                /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
            );

            if (RegExp.$3.indexOf('youtu') > -1) {
                var type = 'youtube';
            } else if (RegExp.$3.indexOf('vimeo') > -1) {
                var type = 'vimeo';
            }

            return {
                type: type,
                id: RegExp.$6
            };
        },
        setYoutubeThumb() {
            this.imgEl.src = `https://img.youtube.com/vi/${this.videoObj.id}/hqdefault.jpg`;
        },

        setVimeoThumb() {
            $.get(
                'https://vimeo.com/api/v2/video/' + this.videoObj.id + '.json',
                data => {
                    this.imgEl.src = data[0].thumbnail_large;
                }
            );
        },
        setYoutubePlayEvent() {
            this.thumbEl.addEventListener('click', e => {
                e.stopPropagation();
                this.thumbEl.remove();
                this.iframe.postMessage(
                    '{"event":"command","func":"playVideo","args":""}',
                    '*'
                );
            });
        },
        setVimeoPlayEvent() {
            this.thumbEl.addEventListener('click', e => {
                e.stopPropagation();
                this.thumbEl.remove();
                this.iframe.postMessage('{"method":"play"}', '*');
            });
        }
    },
    mounted() {
        this.el = document.querySelector('.video' + this.uniqueId);
        this.url = this.el.dataset.videoUrl;
        this.thumbEl = this.el.querySelector('.video__thumb');
        this.videoObj = this.parseVideo();
        this.imgEl = this.el.querySelector('.video__img');
        this.iframeEl = this.el.querySelector('.video__iframe');
        this.iframe = this.iframeEl.contentWindow;

        if (this.videoObj.type === 'youtube') {
            this.setYoutubeThumb();
            this.setYoutubePlayEvent();
        }
        if (this.videoObj.type === 'vimeo') {
            this.setVimeoThumb();
            this.setVimeoPlayEvent();
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class VideoBlock extends Vue {}
