
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { AppStore } from '@interface/store/App';
import PinGhostSvg from '@svg/PinGhost.vue';
import ClockSvg from '@svg/Clock.vue';
import MyNightButton from '@part/elements/MyNightButton.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: { PinGhostSvg, ClockSvg, MyNightButton },
    props: {
        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        },
        data: {
            type: Object,
            required: true
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class EventBlock extends Vue {}
